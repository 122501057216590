/* common styles */
/*
  Change Autocomplete styles in Chrome
  https://css-tricks.com/snippets/css/change-autocomplete-styles-webkit-browsers/
*/

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
}
.grecaptcha-badge{
  display: none !important;
}

.-pre-wrap {
  white-space: pre-wrap;
}
